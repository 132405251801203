@import "mixins";
@import "color-palette";
@import "vars";
@import "breakpoints";

.CardLink {
    text-decoration: none !important;
}

.BlogCard {
    border-radius: $border-radius-12;
    overflow: hidden;
    background-color: $color-bg-white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:not(.DisableShadows):hover {
        box-shadow: $shadow-large-faint;
        border-radius: $border-radius-12;

        @media screen and (min-width: $breakpoint-mobile-md-sm) {
            position: relative;
            z-index: 1;
            transform: scale(1.01);
            transform: translateY(-5px);
        }
    }

    .Image {
        position: relative;
        width: 100%;
        height: 200px;
        min-height: 200px;
        @include MQ(M) {
            height: 30vw;
            min-height: 30vw;
            max-height: 300px;
        }
        @include MQ(S) {
            height: 60vw;
            min-height: 60vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .Content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .Title {
            font-weight: $font-weight-bold;
        }

        .Footer {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $color-text-accent;
            font-size: 0.9rem;
            font-weight: $font-weight-medium;

            .Views {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin-left: 15px;

                .Icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
