@import "mixins";
@import "color-palette";
@import "vars";

.BlogWidget {
    background-color: $color-bg-blue-light;
    padding: 40px 0;
}

.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .Link {
        font-weight: $font-weight-semibold;
        font-size: 15px;
        line-height: 18px;
        color: $color-primary-blue;
    }
}

.Cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include MQ(M) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include MQ(S) {
        grid-template-columns: repeat(1, 1fr);
    }
}
