@import "mixins";
@import "color-palette";
@import "vars";
@import "breakpoints";

.brands {
    background-color: $color-bg-blue-light;
    padding: 20px 0 60px;

    &__inner {
        @include container;
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }

    &__arrows {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }

    &__arrow {
        width: 30px;
        height: 30px;
        border-radius: $border-radius-50-percent;
        background-color: $color-bg-white;
        box-shadow: $shadow-slider-arrows;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &--color {
            fill: $color-bg-accent;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: auto;
        grid-auto-flow: row;
        gap: 16px;
        margin-top: 16px;

        @media (max-width: $breakpoint-tablet-md) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: $breakpoint-mobile-lg) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        font-size: 14px;
        line-height: 16px;
        font-weight: $font-weight-medium;
        color: $color-text-blue;
        padding: 6px;
    }
}