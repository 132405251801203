@import "color-palette";
@import "vars";
@import "breakpoints";

.MainPageTitle {
    padding-top: 150px;
    overflow: hidden;

    @media (max-width: $breakpoint-main-title-md-sm) {
        padding-top: 107px;
    }

    @media (max-width: $breakpoint-main-title-sm-lg) {
        padding-top: 118px;
    }

    @media (max-width: $breakpoint-main-title-sm) {
        padding-top: 106px;
    }

    &__text {
        font-weight: $font-weight-regular;
        font-size: 36px;
        line-height: 117.5%;
        color: $color-text-white;
        margin: 0;

        @media (max-width: $breakpoint-main-title-xs) {
            font-size: 28px;
        }
    }

    &__label {
        font-weight: $font-weight-bold;
        font-size: 40px;
        line-height: 49px;
        color: $color-text-white;

        @media (max-width: $breakpoint-main-title-xs) {
            font-size: 36px;
        }
    }

    &__icons {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(116px, 1fr));
        align-items: center;
        gap: 20px 30px;
        list-style: none;
        padding: 0;
        margin: 35px 0 76px;

        @media (max-width: $breakpoint-main-title-lg) {
            gap: 20px;
        }

        @media (max-width: $breakpoint-main-title-md-lg) {
            margin-bottom: 12px;
        }

        @media (max-width: $breakpoint-main-title-md) {
            margin-bottom: 20px;
        }

        @media (max-width: $breakpoint-main-title-sm-lg) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }

        @media (max-width: $breakpoint-mobile-md-sm) {
            grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
            gap: 16px;
            margin-bottom: 30px;
        }

        @media (max-width: $breakpoint-main-title-sm) {
            gap: 8px 16px;
            margin-bottom: 8px;
        }

        @media (max-width: $breakpoint-main-title-xs) {
            margin-bottom: 26px;
        }

        li:nth-child(9),
        li:nth-child(10) {
            >button {
                >svg {
                    width: auto;
                }
            }
        }
    }

    &__platform {
        height: 100%;
        width: 116px;
        display: block;
        border: none;
        padding: 0;
        cursor: pointer;

        @media (max-width: $breakpoint-main-title-sm-lg) {
            width: 100px;
        }

        @media (max-width: $breakpoint-mobile-md-sm) {
            width: 90px;
        }
    }

    &__item {
        width: auto;
    }
}