@import "color-palette";
@import "mixins";
@import "breakpoints";

.main-screen {
    position: relative;
    background: $color-bg-white;

    &__bg {
        background-image: image-set(
            url('/img/bg2_big.webp') 1x,
            url('/img/bg2_big.jpg') 1x
        );
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: cover;
        height: 500px;
        width: 100%;
        position: absolute;

        @media screen and (min-width: $breakpoint-desktop-lg) {
            background-position: 100% 60%;
        }

        @media screen and (max-width: $breakpoint-mobile-lg) {
            background-image: image-set(
                url('/img/bg2_small.webp') 1x,
                url('/img/bg2_small.jpg') 1x
            );
        }
    }

    &__blackout {
        position: absolute;
        background: $color-blackout;
        width: 100%;
        height: 500px;
        z-index: 3;
        top: 0;
    }

    &__content {
        @include container;
        z-index: 10;
        position: relative;
    }

    &__catalog {
        margin-top: 50px;

        &__catalog-error {
            margin: 2em auto;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}